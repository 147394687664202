import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";

const Wrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > a {
    color: white;
  }
`;

const Text = styled.div`
  margin-bottom: 2rem;
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Wrapper>
        <Text>You're definitely lost, but it's okay :-)</Text>
        <Link to="/">Go home</Link>.
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
